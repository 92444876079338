import MobileDetect from 'mobile-detect';
import { Link } from 'react-router-dom';

import { login } from 'src/app/router/paths';
import { isMobileSafari } from 'src/app/utils/isMobile';

export * from './geo';

export const VERSION = process.env.PACKAGE_JSON_VERSION as string;
// this will give us control on how to treat migrations of different persisted redux versions
export const REDUX_PERSIST_VERSION = 4;

export const DOCUMENT_TITLE = 'Capsule | A better, smarter, kinder pharmacy';

const MOBILE_DETECT = new MobileDetect(typeof window !== 'undefined' ? window.navigator.userAgent : '');
export const IS_MOBILE = !!MOBILE_DETECT.mobile();
export const IS_IPHONE = MOBILE_DETECT.is('iPhone');

// this flag is only for special situations when
// mobile safari has issues compared to things like chrome on an iphone
export const IS_MOBILE_SAFARI = isMobileSafari();

export const IS_PRODUCTION = process.env.NEXT_PUBLIC_CAPSULE_ENV === 'production';

// Versions as of July 3rd, 2023: 1 | 2 | 3 | 4
export const CURRENT_CAPSULE_TOS_VERSION = 4;

export const NAV_HEIGHT = 80;

export const MAX_WIDTH_CONTAINER = '113rem';

export const CAPSULE_HELP_EMAIL = 'help@capsule.com';
export const CAPSULE_DOCTORS_EMAIL = 'doctors@capsule.com';
export const DOCTOR_WHITE_PAPER_PATH = 'https://doctor-whitepapers.s3.amazonaws.com/dummy.pdf';

// MISC

/**
 * string values used to map to specific SVGs to be displayed
 * on various TextInput fields.
 * - ex. 'doctor search' & 'medication search'
 */
export enum FORM_INPUT_ICON_STRINGS {
  doctor = 'doctor',
  medication = 'medication',
  system_search = 'system_search',
  visibility = 'visibility',
  invisibility = 'invisibility',
  chevron_left = 'chevron_left',
}

/**
 * Represents the icon strings for use by the CapsuleIcon component
 */
export const CAPSULE_ICON_STRINGS = {
  rx_bottle: 'rx_bottle',
  rx_filled: 'rx_filled',
  rx_outline: 'rx_outline',
  otc_filled: 'otc_filled',
  otc_outline: 'otc_outline',
  bag: 'bag',
  camera: 'camera',
  capsule_logo: 'capsule_logo',
  capsule_logo_white: 'capsule_logo_white',
  chat_icon: 'chat_icon',
  checkout_account: 'checkout_account',
  checkout_address: 'checkout_address',
  checkout_address_empty: 'checkout_address_empty',
  checkout_allergy: 'checkout_allergy',
  checkout_delivery: 'checkout_delivery',
  checkout_payment: 'checkout_payment',
  checkout_payment_empty: 'checkout_payment_empty',
  checkout_photo: 'checkout_photo',
  check_circle: 'check_circle',
  check_circle_large_green: 'check_circle_large_green',
  chevron_left: 'chevron_left',
  chevron_right: 'chevron_right',
  clipboard: 'clipboard',
  close_circle: 'close_circle',
  coins: 'coins',
  copy_icon: 'copy_icon',
  doctor: FORM_INPUT_ICON_STRINGS.doctor,
  delete_input: 'delete_input',
  envelope_secure: 'envelope_secure',
  first_aid_shield: 'first_aid_shield',
  visibility: FORM_INPUT_ICON_STRINGS.visibility,
  gold_star: 'gold_star',
  grey_star: 'grey_star',
  insurance: 'insurance',
  invisibility: FORM_INPUT_ICON_STRINGS.invisibility,
  little_pill: 'little_pill',
  lock_secure: 'lock_secure',
  medication: FORM_INPUT_ICON_STRINGS.medication,
  minus: 'minus',
  new_upload_icon: 'new_upload_icon',
  old_pharmacy: 'old_pharmacy',
  paper_icon: 'paper_icon',
  paperclip: 'paperclip',
  person: 'person',
  phone_text: 'phone_text',
  pill_heart_icon: 'pill_heart_icon',
  pill_bottle: 'pill_bottle',
  pill_bottle_circle: 'pill_bottle_circle',
  pill_bottle_large: 'pill_bottle_large',
  pill_circle: 'pill_circle',
  pill_section: 'pill_section',
  pill_tall: 'pill_tall',
  pill_triangle: 'pill_triangle',
  pill_diamond: 'pill_diamond',
  play: 'play',
  reset: 'reset',
  stethoscope_icon: 'stethoscope_icon',
  stethoscope_icon_grey: 'stethoscope_icon_grey',
  system_chat: 'system_chat',
  system_info: 'system_info',
  system_more: 'system_more',
  system_nav_back_arrow: 'system_nav_back_arrow',
  system_nav_close: 'system_nav_close',
  system_nav_down_arrow: 'system_nav_down_arrow',
  system_nav_forward_arrow: 'system_nav_forward_arrow',
  system_page_1: 'system_page_1',
  system_radio_button: 'system_radio_button',
  system_search: 'system_search',
  remove: 'remove',
  transferring: 'transferring',
  video_control_pause: 'video_controls_pause',
  video_control_play: 'video_controls_play',
  video_control_replay: 'video_controls_replay',
  yellow_triangle: 'yellow_triangle',
  white_marker: 'white_marker',
  dark_marker: 'dark_marker',
  phone: 'phone',
  close: 'close',
  clock: 'clock',
  mail: 'mail',
  browse_categories_arrow: 'browse_categories_arrow',
  calendar: 'calendar',
  stethoscope: 'stethoscope',
  doctor_office: 'doctor_office',
  up_arrow: 'up_arrow',
  add_icon: 'add_icon',
  tracker_needs_action: 'tracker_needs_action',
  tracker_processing: 'tracker_processing',
};

export const UPLOAD_STRINGS = {
  uploadFieldID: 'uploadFieldID',
  uploadFieldIDCapture: 'uploadFieldIDCapture',
};

/**
 * Represents possible error codes that come back from API
 *
 * @type {object}
 */
export const API_ERROR_CODES = {
  account_password_change_token_is_invalid: 'account_password_change_token_is_invalid',
  payment_processing_error: 'payment_processing_error',
  /**
   * This is returned when `/v2/validate_delivery_address` raises an exception
   * for an address that is not found
   */
  delivery_address_validation_error_not_found: 'delivery_address_validation_error_not_found',
};

/**
 * Represents error codes that come back from BFF (see: https://docs.google.com/spreadsheets/d/1fmjpYJ-EwPyvXrfIpFio7RG2iFm72hDbalL-c2gGbj4/edit?usp=sharing)
 *
 * @type {object}
 */
export const BFF_ERROR_CODES = {
  '15': 'payment_processing_error',
};

export const HOMESCREEN_ITEM_TYPES = {
  transfer: 'transfer',
  prescription: 'prescription',
};

export const REFRIGERATION_TYPES = {
  after_mixing: 'after_mixing',
  no_refrigeration: 'no_refrigeration',
  refrigerate: 'refrigerate',
};

export const PAGE_ALERT_TYPES = {
  refrigerationPageAlert: 'refrigerationPageAlert',
};

export const SNACK_BAR_TYPES = {
  tier_message: 'tier_message',
};

export const FORM_VALIDATION_ERRORS = {
  mobilePhone: 'Please enter your mobile number.',
  birthdate: 'Please enter date of birth.',
  birthdate_max: 'Date of birth cannot be a future date.',
  birthdate_min: 'Please enter a valid birth year.',
  pharmacyName: 'Please enter your pharmacy name.',
  pharmacyDetails: 'Please enter your pharmacy cross streets or address.',
  pharmacyPhone: 'Please enter your pharmacy phone number.',
  medication: 'Please enter your medication name.',
  selectMedication: 'Please select a medication name',
  selectValidMedication: 'Please select a valid medication name',
  firstName: 'Please enter your first name.',
  lastName: 'Please enter your last name.',
  name: 'Please enter your full name.',
  sex: 'Please enter your sex at birth.',
  email: 'Please enter a valid email address.',
  promotionalLandingPageEmail: (
    <>
      This offer is only valid for new customers. Please <Link to={login.toPath()}>log in here.</Link>
    </>
  ),
  password: 'Your password must contain at least 6 characters.',
  zip: 'Please enter a 5-digit zipcode.',
  streetOne: 'Please enter a street address.',
  allergies: 'Please list any allergies or select none below.',
  doctorName: 'Please enter your doctor’s name',
  doctorNameSelect: 'Please select or add a doctor’s name.',
  doctorNumber: 'Please enter your doctor’s number',
  doctorPhoneNumber: 'Please enter a valid 10-digit number',
  familyFirstName: 'Please enter their first name.',
  familyLastName: 'Please enter their last name.',
  familySex: 'Please enter their sex at birth.',
  familyBirthdate: 'Please enter their date of birth.',
  doorman: 'Please select if you have a doorman.',
  specialty: 'Please enter a valid specialty.',
  patientName: "Please enter the patient's name.",
  doctorOfficeAddress: "Please enter your office's address.",
  selectMedicationsPicker: 'Please select at least one medication.',
  selectMedicationsPickerOthers: 'Please enter your other medications.',
};

export const FORM_VALIDATION_PHONE_REGEX = /^[2-9]\d{2}-\d{3}-\d{4}$/;

export const ID_UPLOAD_STATUS = {
  start: 'start',
  empty: 'empty',
  loading: 'loading',
  unverified: 'unverified',
  uploaded: 'uploaded',
  pending: 'pending',
  failed: 'failed',
  verified: 'verified',
};

export const PHOTO_UPLOAD_STRINGS = {
  INSURANCE: {
    NOT_UPLOADED_FRONT_STRING: `Upload or take a photo of the front of your insurance ID card`,
    NOT_UPLOADED_BACK_STRING: `Upload or take a photo of the back of your insurance ID card`,
    IS_UPLOADED_STRING: 'Great! Your health insurance card has been uploaded and will be reviewed by our team.',
    UPLOAD_FAILED_STRING: 'Your verification was declined. Please take another photo or upload one from your device.',
    READY_TO_SUBMIT_MESSAGE: 'Your cards are ready to be submitted',
  },
  DEFAULT: {
    NOT_UPLOADED_STRING: 'Upload a photo of the front of your ID',
    NOT_UPLOADED_STRING_MOBILE: 'Add a photo of the front of your ID',
    IS_UPLOADED_STRING: 'Great, your ID is uploaded!',
    UPLOAD_FAILED_STRING: 'Your verification was declined. Please take another photo or upload one from your device.',
    READY_TO_SUBMIT_MESSAGE: 'Your ID is ready to be submitted',
  },
};

const tryOfficeMessage = 'Another address may have more delivery times available. Try your office instead.';
export const DELIVERY_MESSAGE = {
  T3: tryOfficeMessage,
  T4: tryOfficeMessage,
};

export const INSURANCE_STRINGS = {
  insurance: 'insurance',
  frontCard: 'front_card',
  backCard: 'back_card',
};

export enum ORDER_STATUSES {
  received = 'received',
  processing = 'processing',
  preparing = 'preparing',
  packed = 'packed',
  delivering = 'delivering',
  delivered = 'delivered',
  canceled = 'canceled',
  shipped = 'shipped',
  postponed = 'postponed',
}

export const POPUP_MENU_LABELS = {
  edit: 'Edit',
  delete: 'Delete',
  archive: 'Archive',
  unarchive: 'Unarchive',
  requestRefill: 'Request refill',
  medicationDetails: 'Medication details',
};

export const GENERIC_REFRIGERATION_MESSAGE =
  'Please keep in mind that an item in this order requires refrigeration upon delivery.';

export const SIGNUP_FORM = {
  source: 'web_account_creation',
  campaign: 'utm_content=digital',
};

export const DEFAULT_BIRTHDATE = '1900-01-01';

export const DEFAULT_DEBOUNCE_WAIT = 750;

export const CCPA_LINK = 'https://production-capsule-assets.s3.amazonaws.com/consumer-web/Capsule+CCPA+Notice+.pdf';

export const ESI_MAIL_IDENTIFY_KEY = 'Mail Eligible';
export const ESI_MAIL_KEY = 'ESI_MAIL';

export const INTERSTITIAL_TIMING = 1600;

export const IMAGE_UPLOAD_WIDTH = 1024;
const IMAGE_UPLOAD_HEIGHT = 500;
export const IMAGE_UPLOAD_SIZE = IMAGE_UPLOAD_WIDTH * IMAGE_UPLOAD_HEIGHT;
export const IMAGE_UPLOAD_QUALITY = 0.6;

export const HOME_ELEMENT_IDS = {
  deliveryArea: 'DeliveryArea',
  transferFormSectionId: 'transfer',
};

export const NBA_KEYS = {
  transferRx: 'show_transfer_card_home',
  showYourDoctor: 'show_doctor_card_home',
  addFamilyMember: 'add_family_member',
  viewEssentials: 'view_essentials',
  justSayCapsule: 'just_say_capsule',
  anyDrAnyMed: 'any_dr_any_med',
};

export enum DeliveryMethodType {
  courier = 'COURIER',
  pickup = 'PICKUP',
  pickupStation = 'PICKUP_STATION',
  mail = 'ESI_MAIL',
}

export enum DeliveryMethodLabels {
  freeDelivery = 'Delivery',
  pickUpAtWork = 'Pick up at my office',
}

export enum HomeProductStatus {
  PENDING = 'pending',
  TRANSFER_PROCESSING = 'transfer processing',
  READY_TO_CHECKOUT = 'ready to check out',
  PROCESSING_ORDER = 'processing order',
  OUT_OF_DELIVERY = 'out for delivery',
  REFILL_WILL_BECOME_AVAILABLE = 'refill will become available',
  NO_REFILLS_REMAINING = 'no refills remaining',
  PRESCRIPTION_EXPIRED = 'prescription expired',
}

export enum CTATypes {
  callYourDoctor = 'call-your-doctor',
  callYourPharmacy = 'call-your-pharmacy',
  needInsurance = 'need-insurance',
  showInsuranceList = 'show-insurance-list',
  chatWithUs = 'chat',
  requestRefill = 'request-refill',
  showYourDoctor = 'showYourDoctor',
  transferMeds = 'transferMeds',
  rescheduleOrder = 'reschedule_order',
  rescheduleOrderInvalidAddress = 'reschedule_order_invalid_address',
  scheduleDelivery = 'schedule_delivery',
  openChat = 'open_chat',
  trackOrder = 'track_order',
  viewOtc = 'view_otc',
}

export enum REFILL_STATUSES {
  EXPIRED = 'EXPIRED',
}
